/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Makers Empire')
require("@rails/ujs").start()
require('jquery');

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('bootstrap');

require('custom_shared');

$(function() {
  $('input.check-availability').on('keyup', function(e){
      
    if (e.which == 13) {
      e.preventDefault();
    }

    $("div.icon").hide();
    $("div.wait").show();
    $("#user_username").removeClass('fail success');

    var user = $("#user_username");
    var when_available_show_tick = $(this).attr("data-available-tick") == "true";
    if (user.val().length < 3) {
      $(".wait").hide();
      return;
    }

    if (user.val()) {
      $.ajax("/users/availability", {
        dataType: "json",
        data: {username: user.val()},
        success: function(data) {
          var show_tick = when_available_show_tick ? data : !data

          if (show_tick == true){
            user.addClass('success');
            $('#submit_but').attr("disabled", false);
            $(".available").show();

            if ($("#message").hasClass( "fail" )) {
              $("#message").empty();
            }
          }
          else {
            user.addClass('fail');
            $('#submit_but').attr("disabled", true);
            $(".not-available").show();
            $("#message").html(user.attr("data-unavailable-message")).addClass('fail');
          }
        }
      });
    }

    $(".wait").hide();
  });

  $('input.check-availability').blur();

  // $('form').submit(function(){
  //   $(this).find(':input[type=submit]')
  //          .prop('disabled', true)
  //          .css('background', '#ccc')
  //          .prop('value', 'Processing ... ');
  // });  
});
